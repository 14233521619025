import {
  CampaignMissionListFragment,
  CampaignMissionType,
} from '../graphql/schema';

const TWITTER_BASE_URL = 'https://twitter.com';

const convertEmptyToUndefined = (str?: string) => (str ? str : undefined);

const getTwitterFollowIntentUrl = (screenName?: string) => {
  const url = new URL('/intent/follow', TWITTER_BASE_URL);
  const value = convertEmptyToUndefined(screenName);
  if (value) {
    url.searchParams.set('screen_name', value);
  }

  return url;
};
const getTwitterRetweetIntentUrl = (tweetId?: string) => {
  const url = new URL('/intent/retweet', TWITTER_BASE_URL);
  const value = convertEmptyToUndefined(tweetId);
  if (value) {
    url.searchParams.set('tweet_id', value);
  }

  return url;
};

const getTwitterTweetIntentUrl = (
  text?: string,
  refUrl?: string | null,
  hashtags?: string | null,
) => {
  const url = new URL('/intent/tweet', TWITTER_BASE_URL);

  const value = convertEmptyToUndefined(text);
  if (value) {
    url.searchParams.set('text', value);
  }

  if (refUrl) {
    url.searchParams.set('url', refUrl);
  }

  if (hashtags) {
    url.searchParams.set('hashtags', hashtags);
  }

  return url;
};

export const getMissionStartUrl = (
  campaignMission: CampaignMissionListFragment,
): URL => {
  let url: URL;
  if (campaignMission?.type === CampaignMissionType.TwitterFollow) {
    url = getTwitterFollowIntentUrl(
      campaignMission?.campaignMissionTwitterFollowSetting?.screenName,
    );
  } else if (campaignMission.type === CampaignMissionType.TwitterRetweet) {
    url = getTwitterRetweetIntentUrl(
      campaignMission?.campaignMissionTwitterRetweetSetting?.tweetId,
    );
  } else if (campaignMission.type === CampaignMissionType.TwitterTweet) {
    url = getTwitterTweetIntentUrl(
      campaignMission?.campaignMissionTwitterTweetSetting?.text,
      campaignMission?.campaignMissionTwitterTweetSetting?.url,
      campaignMission?.campaignMissionTwitterTweetSetting?.hashtags,
    );
  } else if (
    campaignMission.type === CampaignMissionType.GeneralPurchase ||
    campaignMission.type === CampaignMissionType.GeneralRead ||
    campaignMission.type === CampaignMissionType.GeneralOpenExternalUrl
  ) {
    url = new URL(campaignMission?.externalContentUrl as string);
  } else if (campaignMission.type === CampaignMissionType.GeneralQuiz) {
    // GeneralQuizの場合はstart url不要なので適当なURLを作成する
    // 空文字列だとエラーになるので、とりあえずwalletのURLを入れておく
    url = new URL('http://wallet.anique.jp');
  } else if (campaignMission.type === CampaignMissionType.Code) {
    url = new URL('http://wallet.anique.jp');
  } else {
    throw Error('unknown mission type');
  }

  return url;
};

// TIPS: next.confgでenvに渡すことで、NEXT_PUBLIC_の修飾子なしでもclientで利用可能に
const questLPSlugs =
  (process.env.RELEASED_QUEST_LP_SLUGS ?? '').split(',') ?? [];

export const hasQuestLandingPageBy = (slug: string) => {
  return questLPSlugs.includes(slug);
};
