export enum RfidErrorType {
  NOT_FOUND_DROP_BY_RFID = 'NOT_FOUND_DROP_BY_RFID',
  RFID_ALREADY_USED = 'RFID_ALREADY_USED',
  //NOTE: APIには定義されてない
  INVALID_RFID_LENGTH = 'INVALID_RFID_LENGTH',
}

export const rfidErrorMessage: Record<string | RfidErrorType, string> = {
  [RfidErrorType.NOT_FOUND_DROP_BY_RFID]: '該当する商品が存在しません',
  [RfidErrorType.RFID_ALREADY_USED]: '入力されたRFIDは使用済みです',
  [RfidErrorType.INVALID_RFID_LENGTH]:
    'スキャンされたコードが無効です。24桁のコードを読み取ってください',
};
